import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultShareTarget(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lineapi/get_default_sharetarget', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListShareTarget(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_list_sharetarget', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getShareTarget(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/get_sharetarget', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addShareTarget(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/add_sharetarget', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editShareTarget(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/edit_sharetarget', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteShareTarget(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/lineapi/delete_sharetarget', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
