<template>
  <b-modal
    id="form-sharetarget"
    :visible="shallShowShareTargetFormModal"
    :title="`${$t('Form')} ${$t('Share Target')}`"
    footer-class="d-flex justify-content-between"
    size="xl"
    centered
    no-close-on-backdrop
    @change="(val) => $emit('update:shall-show-share-target-form-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title">
        {{ `${$t(typeShareTargetForm)} ${$t('Share Target')}` }}
      </h5>
      <div class="modal-actions">
        <feather-icon
          icon="MinusIcon"
          class="cursor-pointer"
          @click="(busy === false) ? $emit('update:shall-show-share-target-form-modal', false) : false"
        />
        <!-- <feather-icon
          icon="Maximize2Icon"
          class="ml-1 cursor-pointer"
        /> -->
        <feather-icon
          icon="XIcon"
          class="ml-1 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <!-- Footer: Left Content -->
      <div>
        <b-button
          v-if="(typeShareTargetForm !== 'View')"
          ref="submit"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          right
          :disabled="busy"
          @click="onSubmit"
        >
          {{ (typeShareTargetForm === 'Add' || typeShareTargetForm === 'Edit') ? $t('Save Data') : $t('Delete Data') }}
        </b-button>
      </div>

      <!-- Footer: Right Content -->
      <div>
        <!-- Discard -->
        <feather-icon
          icon="XCircleIcon"
          size="17"
          class="ml-75 cursor-pointer"
          @click="(busy === false) ? onDiscard() : false"
        />
      </div>
    </template>

    <!-- Modal: Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <div>
        <b-form
          ref="refForm"
          class="position-relative"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <div>
            <b-card style="margin-bottom: 0.6rem;">
              <div>
                <b-row>

                  <!-- Field: Name -->
                  <b-col
                    cols="12"
                  >
                    <b-form-group
                      v-if="(typeShareTargetForm === 'Add' || typeShareTargetForm === 'Edit')"
                      :label="$t('Share Target Name')"
                      label-for="sharetarget-name"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Share Target Name')"
                        rules="required"
                      >
                        <b-form-input
                          id="sharetarget-name"
                          v-model="shareTargetData.name"
                          :state="getValidationState(validationContext)"
                          :disabled="busy"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Share Target Name')"
                      label-for="sharetarget-name"
                    >
                      <span class="form-info-box">{{ shareTargetData.name }}</span>
                    </b-form-group>
                  </b-col>

                </b-row>

                <!-- Type Button -->
                <b-row
                  v-if="(typeShareTargetForm === 'Add' || typeShareTargetForm === 'Edit')"
                >

                  <!-- Field: Select Message Type (Share) -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      :label="`${$t('Select Message Type')} (${$t('Share Message')})`"
                    >
                      <b-button
                        :variant="(shareTargetData.share_type === 'flex')? 'info' : 'outline-info'"
                        @click="onSelectShareMessageType('flex')"
                      >
                        {{ $t('Flex Message') }}
                      </b-button>

                      <b-button
                        class="ml-1"
                        :variant="(shareTargetData.share_type === 'text')? 'info' : 'outline-info'"
                        @click="onSelectShareMessageType('text')"
                      >
                        {{ $t('Text') }}
                      </b-button>

                    </b-form-group>
                  </b-col>

                  <!-- Field: Select Message Type (Target) -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      :label="`${$t('Select Message Type')} (${$t('Target Message')})`"
                    >
                      <b-button
                        :variant="(shareTargetData.target_type === 'flex')? 'info' : 'outline-info'"
                        @click="onSelectTargetMessageType('flex')"
                      >
                        {{ $t('Flex Message') }}
                      </b-button>

                      <b-button
                        class="ml-1"
                        :variant="(shareTargetData.target_type === 'text')? 'info' : 'outline-info'"
                        @click="onSelectTargetMessageType('text')"
                      >
                        {{ $t('Text') }}
                      </b-button>

                    </b-form-group>
                  </b-col>

                </b-row>

                <!-- Input by Type -->
                <b-row
                  v-if="(typeShareTargetForm === 'Add' || typeShareTargetForm === 'Edit')"
                >

                  <!-- Field: Other -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="shareTargetData.share_type === 'flex'"
                      :label="`${$t('Display Message')} (${$t('Share Message')})`"
                      label-for="sharetarget-share-flex"
                    >
                      <b-form-textarea
                        v-model="shareTargetData.share_flex"
                        rows="1"
                        :placeholder="$t('Display Message')"
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="shareTargetData.share_type === 'text'"
                      :label="`${$t('Text Message')} (${$t('Share Message')})`"
                      label-for="sharetarget-share-text"
                    >
                      <b-form-textarea
                        v-model="shareTargetData.share_text"
                        rows="1"
                        :placeholder="$t('Text Message')"
                        @keyup="keyupShareText"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: Target Flex -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="shareTargetData.target_type === 'flex'"
                      :label="`${$t('Display Message')} (${$t('Target Message')})`"
                      label-for="sharetarget-target-flex"
                    >
                      <b-form-textarea
                        v-model="shareTargetData.target_flex"
                        rows="1"
                        :placeholder="$t('Display Message')"
                      />
                    </b-form-group>

                    <b-form-group
                      v-if="shareTargetData.target_type === 'text'"
                      :label="`${$t('Text Message')} (${$t('Target Message')})`"
                      label-for="sharetarget-target-text"
                    >
                      <b-form-textarea
                        v-model="shareTargetData.target_text"
                        rows="1"
                        :placeholder="$t('Text Message')"
                        @keyup="keyupTargetText"
                      />
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row
                  v-if="(typeShareTargetForm === 'Add' || typeShareTargetForm === 'Edit')"
                  class="mb-1"
                >

                  <!-- Field: Other -->
                  <b-col
                    cols="6"
                  >
                    <span v-if="shareTargetData.share_type === 'flex'">
                      ดูรายละเอียดวิธีปรับแต่ง Flex Message ตามต้องการด้วย JSON
                      <a
                        href="https://developers.line.biz/flex-simulator/"
                        target="_blank"
                      >
                        <feather-icon
                          icon="ExternalLinkIcon"
                          size="18"
                          style="margin-top: -4px;"
                        />
                        สร้าง Flex Message
                      </a>
                    </span>
                    <span v-if="shareTargetData.share_type === 'text'">
                      ป้อนข้อความที่ต้องการ JSON จะสร้างขึ้นมาอัตโนมัติ
                    </span>
                  </b-col>

                  <!-- Field: Target Flex -->
                  <b-col
                    cols="6"
                  >
                    <span v-if="shareTargetData.target_type === 'flex'">
                      ดูรายละเอียดวิธีปรับแต่ง Flex Message ตามต้องการด้วย JSON
                      <a
                        href="https://developers.line.biz/flex-simulator/"
                        target="_blank"
                      >
                        <feather-icon
                          icon="ExternalLinkIcon"
                          size="18"
                          style="margin-top: -4px;"
                        />
                        สร้าง Flex Message
                      </a>
                    </span>
                    <span v-if="shareTargetData.target_type === 'text'">
                      ป้อนข้อความที่ต้องการ JSON จะสร้างขึ้นมาอัตโนมัติ
                    </span>
                  </b-col>

                </b-row>

                <b-row>

                  <!-- Field: Share JSON Message -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeShareTargetForm === 'Add' || typeShareTargetForm === 'Edit')"
                      :label="`${$t('JSON Message')} (${$t('Share Message')})`"
                      label-for="sharetarget-share-json"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="`${$t('JSON Message')} (${$t('Share Message')})`"
                        rules="required"
                      >
                        <v-jsoneditor
                          v-model="shareTargetData.share_json"
                          :options="optionsShareMessage"
                          :plus="false"
                          height="300px"
                          @error="onErrorShareMessage"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="`${$t('JSON Message')} (${$t('Share Message')})`"
                      label-for="sharetarget-share-json"
                    >
                      <v-jsoneditor
                        v-model="shareTargetData.share_json"
                        :options="optionsViewMessage"
                        :plus="false"
                        height="300px"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: Target JSON Message -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeShareTargetForm === 'Add' || typeShareTargetForm === 'Edit')"
                      :label="`${$t('JSON Message')} (${$t('Target Message')})`"
                      label-for="sharetarget-target-json"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="`${$t('JSON Message')} (${$t('Target Message')})`"
                        rules="required"
                      >
                        <v-jsoneditor
                          v-model="shareTargetData.target_json"
                          :options="optionsTargetMessage"
                          :plus="false"
                          height="300px"
                          @error="onErrorTargetMessage"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="`${$t('JSON Message')} (${$t('Target Message')})`"
                      label-for="sharetarget-target-json"
                    >
                      <v-jsoneditor
                        v-model="shareTargetData.target_json"
                        :options="optionsViewMessage"
                        :plus="false"
                        height="300px"
                      />
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row>

                  <!-- Field: Link -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeShareTargetForm === 'Add' || typeShareTargetForm === 'Edit')"
                      :label="$t('Link')"
                      label-for="sharetarget-link"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Link')"
                      >
                        <b-form-input
                          id="sharetarget-link"
                          v-model="shareTargetData.link"
                          :state="getValidationState(validationContext)"
                          :disabled="true"
                        />
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Link')"
                      label-for="sharetarget-link"
                    >
                      <span class="form-info-box">{{ shareTargetData.link }}</span>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Status -->
                  <b-col
                    cols="6"
                  >
                    <b-form-group
                      v-if="(typeShareTargetForm === 'Add' || typeShareTargetForm === 'Edit')"
                      :label="$t('Status')"
                      label-for="sharetarget-status"
                    >
                      <validation-provider
                        #default="validationContext"
                        :name="$t('Status')"
                        rules="required"
                      >
                        <b-form-radio
                          v-model="shareTargetData.status"
                          name="sharetarget-status"
                          value="active"
                          class="custom-control-success cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Active') }}</span>
                        </b-form-radio>
                        <b-form-radio
                          v-model="shareTargetData.status"
                          name="sharetarget-status"
                          value="inactive"
                          class="custom-control-info cursor-pointer"
                          inline
                        >
                          <span class="cursor-pointer">{{ $t('Inactive') }}</span>
                        </b-form-radio>
                        <small class="text-danger">{{ validationContext.errors[0] }}</small>
                      </validation-provider>

                    </b-form-group>

                    <b-form-group
                      v-else
                      :label="$t('Status')"
                      label-for="sharetarget-status"
                    >
                      <span class="form-info-box">{{ $t(textFirstUpper(shareTargetData.status)) }}</span>
                    </b-form-group>
                  </b-col>

                </b-row>

              </div>
            </b-card>
          </div>

        </b-form>

      </div>

    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BButton, BForm,
  BFormGroup, BFormInput,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BProgress,
  BCardText,
  BFormRadio,
  BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  ValidationProvider,
  ValidationObserver,
} from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import VJsoneditor from 'v-jsoneditor'
import tableShareTargetList from './tableShareTargetList'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BProgress,
    BCardText,
    BFormRadio,
    BFormTextarea,

    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
    VJsoneditor,
  },
  model: {
    prop: 'shallShowShareTargetFormModal',
    event: 'update:shall-show-share-target-form-modal',
  },
  props: {
    shallShowShareTargetFormModal: {
      type: Boolean,
      required: true,
    },
    typeShareTargetForm: {
      type: String,
      default: 'Add',
    },
    shareTargetData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      skin: store.state.appConfig.layout.skin,
      optionsShareMessage: {
        mode: 'code',
        mainMenuBar: true,
        enableSort: false,
        enableTransform: false,
        statusBar: false,
      },
      optionsTargetMessage: {
        mode: 'code',
        mainMenuBar: true,
        enableSort: false,
        enableTransform: false,
        statusBar: false,
      },
      optionsViewMessage: {
        mode: 'code',
        mainMenuBar: false,
        enableSort: false,
        enableTransform: false,
        statusBar: false,
      },
      share_message_paste: '',
      target_message_paste: '',
    }
  },
  methods: {
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialog.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.typeShareTargetForm === 'Add') {
        store.dispatch('sharetarget-store/addShareTarget', this.shareTargetData)
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('share-target-form-update')
            this.$emit('update:shall-show-share-target-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeShareTargetForm === 'Edit') {
        store.dispatch('sharetarget-store/editShareTarget', { id: this.shareTargetData.id, data: this.shareTargetData })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('sharetarget-form-update')
            this.$emit('update:shall-show-share-target-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.typeShareTargetForm === 'Delete') {
        store.dispatch('sharetarget-store/deleteShareTarget', {
          id: this.shareTargetData.id,
        })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false

            this.$emit('sharetarget-form-update')
            this.$emit('update:shall-show-share-target-form-modal', false)
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              const errorMsg = this.$i18n.t(response?.data.error)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
    },
    onDiscard() {
      this.$emit('discard-share-target-form')
      this.$emit('update:shall-show-share-target-form-modal', false)
    },
    onErrorShareMessage() {
      // console.log('error')
    },
    onErrorTargetMessage() {
      // console.log('error')
    },
    onSelectShareMessageType(type) {
      this.shareTargetData.share_type = type
    },
    onSelectTargetMessageType(type) {
      this.shareTargetData.target_type = type
    },
    keyupShareText() {
      this.shareTargetData.share_json = {
        type: 'text',
        text: this.shareTargetData.share_text,
      }
    },
    keyupTargetText() {
      this.shareTargetData.target_json = {
        type: 'text',
        text: this.shareTargetData.target_text,
      }
    },
  },
  setup() {
    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    const {
      textFirstUpper,
      isStatusVariant,
      isStatusToText,
    } = tableShareTargetList()

    return {
      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      textFirstUpper,
      isStatusVariant,
      isStatusToText,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/_variables';
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.modal{
  .line-height-info{
    padding-bottom: 0.6rem;
    &.end{
      padding-bottom: 0;
    }
  }
  .modal-header,
  .modal-header[class*='bg-'] {
    background-color: #fff !important;
  }
  .modal-body {
    background-color: $body-bg !important;
  }
}
.form-info-box{
  display: flex;
  padding: 0.6rem 1rem;
  background-color: #f8f8f8;
  background-clip: padding-box;
  border: 1px solid #f0f0f0;
  border-radius: 0.357rem;
}

.dark-layout {
  .modal{
    .line-height-info{
      padding-bottom: 0.6rem;
      &.end{
        padding-bottom: 0;
      }
    }
    .modal-header,
    .modal-header[class*='bg-'] {
      background-color: $theme-dark-body-bg !important;
    }
    .modal-body {
      background-color: $theme-dark-table-active-bg !important;
    }
  }
  .form-info-box{
    display: flex;
    padding: 0.6rem 1rem;
    background-color: $theme-dark-input-bg;
    background-clip: padding-box;
    border: 1px solid $theme-dark-border-color;
    border-radius: 0.357rem;
  }
}
.jsoneditor-poweredBy {
  display: none !important;
}
.jsoneditor {
  border: thin solid #d8d6de !important;
}
.jsoneditor-menu{
  background-color: #aaaaaa !important;
  border-bottom: 1px solid #aaaaaa !important;
}
</style>
